import React from "react";
import { Container, Navbar } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import Division from "../Elements/Division";

const MarcusManuh = () => {
  <style jsx>
    {`
      .testing {
      display: none !important;
        }

        media (max-width: 600px) {
          .testing {
          display: none !important;
          }
        }
      }
    `}
  </style>;
  return (
    <span id="clients" style={{ overflowX: "hidden" }}>
      <Navbar style={{ background: "#7573bf" }}>
        <Container>
          <Navbar.Brand style={{ color: "white" }}>
            <strong>Personal message:</strong>
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Container>
        <main style={{ padding: "2rem 0", minHeight: "80vh" }}>
          <Fade>
            <h4 style={{ marginBottom: "2rem" }}>
              Thank you for interviewing me 😃
            </h4>
            <div style={{ display: "inline" }}></div>
          </Fade>
          <p style={{ lineHeight: "2rem" }}>
            It was such a great experience visiting the campus — what an amazing
            place to work! After leaving the building, I realized I probably
            forgot adding a <code>key</code> value to the{" "}
            <code>&lt;li&gt;</code> items — which would had led to a perfect
            conversation of why (when an <code>id</code> is available) we avoid
            using <code>index</code> as a key. That would had been an
            interesting conversation!
          </p>
          <p style={{ lineHeight: "2rem" }}>
            Your questions were a great window into the techincal-vocabulary of
            many of concepts I have been using, and I am disabling my
            <code> vs-code </code> snippets for a while to sharpen my raw syntax
            💪🏾🔥.
          </p>
          <p style={{ lineHeight: "2rem" }}>
            I’m super excited about the opportunity to work with you and the
            team. If it doesn’t work out this time, I’m still incredibly
            grateful for the experience and insight. Blessings!
          </p>
          <br />
          <div>
            All the best,
            <br />
            <strong>Alfredo R. Pabón</strong>
            <br />
            Front-End Developer
            <br />
            <a
              href="mailto:alfredrafael@gmail.com"
              style={{ textDecoration: "none" }}
            >
              alfredrafael@gmail.com
            </a>
            <br />
            <button
              onClick={() => (window.location.href = "tel:+16175801400")}
              style={{
                background: "none",
                border: "none",
                color: "inherit",
                textDecoration: "underline",
                cursor: "pointer",
                padding: "0",
              }}
            >
              <small>(617) 580-1400</small>
            </button>
          </div>
          <Division />
        </main>
      </Container>
    </span>
  );
};

export default MarcusManuh;

{
  /* <p style={{ lineHeight: "2rem" }}>
            It was such a great experience, and an amazing campus! After leaving
            the building I remembered not adding a{" "}
            <code>
              <strong>key</strong>
            </code>{" "}
            to the <code>&lt;li&gt;</code> items, leaving an interesting
            conversation as for why (if an <code>id</code> is available) we
            never want to use
            <code>
              <strong> index </strong>
            </code>{" "}
            as such. Your questions were a great window into the{" "}
            <strong>
              <i>techincal vocabulary</i>
            </strong>{" "}
            of many of the concepts I been using, and I am disabling my
            <code>
              <strong> vscode </strong>
            </code>
            snippets for a bit, to keep that syntax sharp 💪🏾🔥.
          </p> */
}

{
  /*             
            <a
              href="https://cateringwebsitetest.com/"
              target="_blank"
              rel="noreferrer"
            >
              <strong>our template website </strong>
            </a> */
}
